import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,2,3,4,5,6,7,9,10,11];

export const dictionary = {
		"/": [~12],
		"/about-us": [13],
		"/admin": [14,[2]],
		"/admin/stats": [15,[2,3]],
		"/admin/templates": [16,[2]],
		"/admin/templates/new": [~23,[2]],
		"/admin/templates/[slug]": [17,[2,4]],
		"/admin/templates/[slug]/metadata": [~18,[2,4]],
		"/admin/templates/[slug]/pages": [~19,[2,4]],
		"/admin/templates/[slug]/pages/[page]": [~20,[2,4]],
		"/admin/templates/[slug]/publish": [~21,[2,4]],
		"/admin/templates/[slug]/settings": [~22,[2,4]],
		"/books": [24,[5]],
		"/books/templates": [25,[5]],
		"/books/templates/new": [~31,[5]],
		"/books/templates/[slug]": [26,[5,6]],
		"/books/templates/[slug]/metadata": [~27,[5,6]],
		"/books/templates/[slug]/pages": [~28,[5,6]],
		"/books/templates/[slug]/pages/[page]": [~29,[5,6]],
		"/books/templates/[slug]/settings": [~30,[5,6]],
		"/build-a-book": [32],
		"/characters": [~33,[7]],
		"/characters/create": [~35,[7]],
		"/characters/[uid]": [~34,[7]],
		"/login": [~36],
		"/policies/cookies": [37,[8]],
		"/policies/privacy": [38,[8]],
		"/policies/terms": [39,[8]],
		"/settings": [40,[9]],
		"/settings/profile": [41,[9]],
		"/stories": [~42],
		"/stories/[slug]": [43,[10]],
		"/stories/[slug]/imagine": [44,[10]],
		"/stories/[slug]/pages/[pageUID]": [45,[10,11]],
		"/subscribe": [46]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';